<template>
  <div>
    <el-dialog :close-on-click-modal="false" title="会员卡列表" :visible.sync="mcVisible" width="650px"
               :before-close="closeEvent">
      <div class="mc-items">
        <div class="mc-item" v-for="(item,index) in memberCards">
          <div class="mc-card">
            <img src="http://47.108.51.191:80/group1/M00/00/0A/rBBr2mBgtGeAe_rqAASh4rKyU2c993.jpg" class="mc-img"/>
            <span class="mci-name">{{item.cardName}}
         <span v-if="item.cardType==0" style="color:#A2A2A2;font-size: 10px"> (储值卡)</span>
         <span v-if="item.cardType==1" style="color:#A2A2A2;font-size: 10px">(年卡)</span>
         <span v-if="item.cardType==2" style="color:#A2A2A2;font-size: 10px">(消费卡)</span>
        </span>
            <span class="mci-price">¥ {{item.balance}} <span style="float: right;margin-right: 5px">赠 ¥ {{item.giveBalance}}</span></span>
          </div>
          <div style="display: flex;flex-direction: row">
            <span class="mc-cz" v-if="item.id==memberCardId">当前会员卡</span>
            <span class="mc-cz" style=" cursor: pointer;" v-if="item.id!=memberCardId" @click="setMemberCard(item)">选择会员卡</span>
            <span class="mc-cz" style="margin-left: 8px; cursor: pointer;background-color: #00ACEF;color: #ffffff"
                  @click="toRecharge(item)">充值</span>
          </div>
        </div>
      </div>
    </el-dialog>
    <Recharge
      v-if="payVisible"
      :payVisible="payVisible"
      :memberCard="memberCard"
      @fanhui="fanhui"
      @closePay="closePay"
    />
  </div>
</template>
<script>
  import Recharge from "./recharge";

  export default {
    components: {
      Recharge,
    },
    data() {
      return {
        payVisible: false,
        memberCard: {},
        memberCards:[],
      }
    },
    props: {
      mcVisible: Boolean,
      memberCardId:String,
      memberId:String
    },

    created() {
      console.log(this.memberCardId)
      this.getMemberCards()
    },

    methods: {

      //获取会员信息
      async getMemberCards() {
        let res = await this.$get("/sw/getSwMbmberCards", {memberId: this.memberId});
        if (res.memberCards.length>0) {
          this.memberCards = res.memberCards
        }
      },
      closeEvent() {
        this.$emit('closeMC')
      },
      czEvent() {
        this.payVisible = false
      },
      closePay() {
        this.$message.success("充值成功")
        this.payVisible = false
        this.$emit('recharge')
      },
      fanhui() {
        this.payVisible = false
      },
      toRecharge(item) {
        this.memberCard = item
        this.payVisible = true
      },

      setMemberCard(item) {
        this.$emit('setMc', item)
      },
    }
  }
</script>
<style lang="scss">
  .mc-items {
    width: 100%;
    margin-top: -18px;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 18px;
    height: 380px;
    overflow: auto;
  }

  .mc-item {
    flex: 1;
    box-sizing: border-box;
    height: 138px;
    padding: 8px;
    margin-bottom: 38px;
    width: calc(100% / 3); // 我这里一行显示4个 所以是/4  一行显示几个就除以几
    min-width: calc(100% / 3);
    max-width: calc(100% / 3);

    &:nth-child(3n + 3) {
      margin-right: 0px;
    }
  }

  .mc-card {
    display: flex;
    flex-direction: column;
  }

  .mc-img {
    width: 100%;
    height: 78px;
    border-radius: 3px;
  }

  .mci-name {
    margin-top: 3px;
    line-height: 16px;
    margin-left: 5px;
    font-size: 10px;
  }

  .mci-price {
    line-height: 16px;
    margin-left: 5px;
    color: red;
    font-size: 10px;
  }

  .mc-cz {
    width: 50%;
    text-align: center;
    margin-top: 6px;
    height: 20px;
    line-height: 20px;
    border: #DFDFDF solid 1px;
    font-size: 11px;
  }

</style>
