<template>
  <div class="web-billing">
    <div class="big-left">
      <div class="bl-top">
      </div>
      <div class="bl-bottom">
        <div class="bb-item" @click="toMemberList">
          <img src="../../assets/images/tx.jpg" class="bc-img">
          <span>选择会员</span>
        </div>
        <div class="bb-item" v-if="member.id" @click="toMemberCards">
          <span v-if="memberCard.cardName">{{memberCard.cardName}}</span>
          <span style="margin-top: 6px;color: red;font-size: 13px"
                v-if="memberCard.cardName">¥ {{memberCard.balance}}</span>
          <span v-else>会员卡</span>
        </div>
        <div class="bb-item" v-if="member.id">
          <img v-if="member" src="../../assets/images/tx1.jpg" class="bc-img">
          <img v-if="!member" src="../../assets/images/tx.jpg" class="bc-img">
          <span v-if="member">{{member.memberName}}</span>
        </div>
      </div>
    </div>
    <div class="big-right">
      <div class="br-top">
        <TabItem
          v-if="tabIndex == 0"
          ref="tabItem"
          :itemData="itemData"
          @setItems="setItems"
        />
      </div>
      <div class="br-bottom">
        <div class="br-btn">
          <div style="width: 50%;">
            <span class="br-sex" :class="{activeSex:orderSex==1}" @click="setOrderSex(1)">男</span>
            <span class="br-sex" :class="{activeSex:orderSex==2}" @click="setOrderSex(2)">女</span>
          </div>
          <div style="width: 50%;float: right;display: flex;flex-direction: row">
            <span class="zj">总计：</span>
            <span class="total">￥{{ total }}</span>
            <button class="payment" @click="savePxOrder">结 算</button>
          </div>
        </div>
      </div>
    </div>

    <TabMemberList
      v-if="mbVisible"
      :mbVisible="mbVisible"
      @setMember="setMember"
      @setMemberCard="setMemberCard"
      @closeML="closeML"
      @sanke="sanke"
    />

    <TabMemberCards
      v-if="mcVisible"
      :mcVisible="mcVisible"
      :memberId="memberId"
      :memberCardId="mcId"
      @setMc="setMc"
      @recharge="recharge"
      @closeMC="closeMC"
    />


    <TabPayment
      v-if="payVisible"
      :payVisible="payVisible"
      :total="total"
      :member="member"
      :memberCard="memberCard"
      :data="data"
      @closePay="closePay"
    />

  </div>
</template>

<script>
  import {getToken} from "../../utils/auth";
  import TabMemberList from "./memberList";
  import TabMemberCards from "../common/member/memberCards";
  import TabPayment from "./payment";
  import TabItem from "./menber/items";

  export default {
    name: "px",
    components: {
      TabMemberList,
      TabMemberCards,
      TabPayment,
      TabItem,
    },
    data() {
      return {
        data: {},
        orderSex: 1,
        tabIndex: 0,
        mcId: '',
        memberId: '',

        itemData: [],

        member: {},
        memberCard: {},

        itemTotal: 0,
        total: 0,

        mbVisible: false,
        mcVisible: false,
        payVisible: false,
      };
    },

    async created() {
    },

    methods: {
      //获取会员信息
      async getMember() {
        let res = await this.$get("/sw/getSwMbmber", {memberId: this.memberId});
        this.member = res.member
        if (res.member) {
          this.activeSex = res.member.sex
        }
        this.memberCards = res.memberCards
        if (res.memberCards.length > 0) {
          this.memberCard = res.memberCards[0]
        }
      },

      //编辑会员姓名
      toUpdateName() {

      },

      //选择页面
      choicePage(index) {
        this.tabIndex = index
      },

      //选择性别
      setOrderSex(sex) {
        if (this.member.id) {
          return;
        }
        this.orderSex = sex
      },

      //设置项目列表
      setItems(items, total) {
        this.itemData = items
        this.total = total
      },


      toMemberCards() {
        this.mcVisible = true
      },

      toMemberList() {
        this.mbVisible = true
      },

      //选择会员信息
      setMember(item) {
        this.member = item;
        if (item) {
          this.activeSex = item.sex
        }
        this.memberId = item.id
        this.closeML();
      },

      setMemberCard(mb, mc) {
        console.log(mb)
        console.log(mc)
        this.smVisible = false
        if (mb.id) {
          this.member = mb;
          this.memberId = mb.id
          this.activeSex = mb.sex
        }
        if (mc.id) {
          this.mcId = mc.id
          this.memberCard = mc
        }
        this.closeML();
      },
      setMc(item) {
        this.memberCard = item
        this.mcId = item.id
        this.mcVisible = false;
      },

      sanke() {
        this.memberId = ''
        this.member = {}
        this.mcId = ''
        this.memberCard = {}
        this.activeSex = 1
        this.closeML();
      },

      //计算总金额
      calculation(total) {
        this.total = total
      },


      async savePxOrder() {
        let mcId = ''
        if (this.memberCard && this.memberCard.id) {
          mcId = this.memberCard.id
        }
        var data = {
          itemStr: JSON.stringify(this.itemData),
          total: this.total,
          memberId: this.memberId,
          mcId: mcId,
          orderSex: this.orderSex,
          token: getToken(),
          payType: '',
        }
        this.data = data
        this.payVisible = true;
      },

      recharge() {
        if (this.memberCard.id) {
          this.getMember(this.memberCard.id)
        }
        this.mcVisible = false
      },

      closeMC() {
        this.mcVisible = false;
      },

      closePay() {
        this.payVisible = false;
      },


      toMembers() {
        this.mbVisible = true;
      },

      closeML() {
        this.mbVisible = false;
      },

    },

  };
</script>


<style lang="scss" scoped>
  .web-billing {
    width: 100%;
    height: 100%;
    background-color: #F2F2F2;
    margin-right: 5px;
    display: flex;
    flex-direction: row;
  }

  .big-left {
    width: 78px;
    border-right: #DDDDDD solid 1px;
  }

  .bl-top {
    height: 50%;
  }

  .bt-item {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 20%;
    justify-content: center;
    align-items: center;
    border-bottom: #DDDDDD solid 1px;
  }

  .bt-number {
    color: #ff4d51;
    font-size: 28px;
    margin-bottom: 8px;
    font-weight: bold;
  }

  .bt-text {
    color: #A2A2A2;
    font-size: 15px;
  }

  .bt-name {

  }

  .bt-price {
    width: 60px;
    color: #FFFFFF;
    background-color: #DE4673;
    margin-top: -6px;
    margin-bottom: 10px;
    height: 18px;
    line-height: 18px;
    border-radius: 5px;
  }

  .bl-bottom {
    display: flex;
    flex-direction: column-reverse;
    height: 50%;
    width: 80px;
    font-size: 10px;
  }

  .bb-user {
    height: 15%;
  }

  .bb-card {
    height: 15%;
  }

  .bb-tc {
    height: 15%;
  }

  .bb-shop {
    height: 15%;
  }

  .bb-item {
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    justify-content: center;
    border-top: #DDDDDD solid 1px;
    cursor: pointer;
  }

  .bb-item span {

  }

  .bc-img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-bottom: 6px;
  }

  .big-right {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
  }

  .br-top {
    height: 100%;
  }

  .br-bottom {
    width: 100%;
    height: 60px;
    border-top: #DDDDDD solid 1px;
  }

  .br-btn {
    width: 100%;
    /*height: 100%;*/
    float: right;
    display: flex;
    align-items: center;
  }

  .br-btn .zj {
    width: 45%;
    line-height: 60px;
    text-align: right;
  }

  .br-btn .total {
    width: 120px;
    line-height: 60px;
    color: red;
  }

  .payment {
    width: 150px;
    height: 60px;
    border: 0;
    background-color: #e82742;
    color: #fff;
    cursor: pointer;
  }

  .br-save {
    width: 150px;
    height: 60px;
    border: 0;
    background-color: #595959;
    color: #fff;
    cursor: pointer;
    margin-left: 18px;
  }

  .br-sex {
    display: inline-block;
    background-color: #C2C6CD;
    margin-left: 12px;
    width: 32px;
    text-align: center;
    line-height: 32px;
    height: 32px;
    border-radius: 45px;
    font-size: 16px;
    cursor: pointer;
  }

  .activeSex {
    background-color: #666666;
    color: #ffffff;
  }

</style>
