<template>
  <div class="item-top">
    <div class="imt-left">
      <div class="imt-cats">
        <span class="imt-title" v-if="categoryList.length>0" v-for="(item,index) in categoryList"
              :class="{activeCat:catIndex==index}" @click="choiceCat(index,item.id)">{{item.catName}}</span>
        <span class="imt-title1" v-if="!categoryList.length>0">暂无分类</span>
      </div>
      <div class="imt-list">
        <div class="imt-items" v-if="items.length>0">
          <div @click="addItem(item)" class="imt-item" v-for="item in items">
            <span class="imt-name">{{item.itemName}}</span>
            <span class="imt-price">¥ {{item.price}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="imt-right">
      <div class="buy-item">
        <el-table
          :data="datas"
          @row-click="setRow"
          height="100%"
          highlight-current-row
          ref="singleTableRef"
          style="width: 100%">
          <el-table-column align="center" width="50">
            <template slot-scope="scope">
              <li @click.stop="delItem(scope.$index)" class="el-icon-delete-solid"
                  style="color:red;cursor: pointer;"></li>
            </template>
          </el-table-column>
          <el-table-column align="center" label="名称" property="itemName">
          </el-table-column>
          <el-table-column align="center" label="价格" property="tcPrice" width="100">
            <template slot-scope="scope">
              <el-input-number
                style="width: 90px"
                size="mini"
                v-model="scope.row.price"
                auto-complete="off"
                :precision="1"
                :controls="false"
                maxlength="6"
                v-on:input="calculation()"
              ></el-input-number>
            </template>
          </el-table-column>
          <el-table-column align="center" :label="fName" v-if="fEmps.length>0" property="empName">
            <template slot-scope="scope">
              <div class="buy-xiaoshou" v-if="scope.row.femp">
                <span v-if="scope.row.femp.empName" style="width: 60px;line-height: 28px;font-size: 13px">{{scope.row.femp.empName}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" :label="sName" v-if="sEmps.length>0" property="empName">
            <template slot-scope="scope">
              <div class="buy-xiaoshou" v-if="scope.row.semp">
                <span style="width: 60px;line-height: 28px;font-size: 13px">{{scope.row.semp.empName}}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" :label="tName" v-if="tEmps.length>0" property="empName">
            <template slot-scope="scope">
              <div class="buy-xiaoshou" v-if="scope.row.temp">
                <span style="width: 60px;line-height: 28px;font-size: 13px">{{scope.row.temp.empName}}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="imt-emp" v-if="exist">
        <div class="imt-gw" v-if="fEmps.length>0">
          <span class="gw-title">{{fName}}</span>
          <div class="emp-h">
            <div class="gw-emp" v-for="item in fEmps">
              <div class="emp-st">
                <span class="name" @click="setLEmp(item,1)" @dblclick="setDEmp(item,1)">{{item.nickName}}</span>
                <div class="emp-st-btn">
                  <div class="leftActive" v-if="item.status==1" @click="setUnchecked(1)">
                    轮
                  </div>
                  <div class="rightActive" v-if="item.status==2" @click="setUnchecked(1)">
                    点
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="imt-gw" v-if="sEmps.length>0">
          <span class="gw-title">{{sName}}</span>
          <div class="emp-h">
            <div class="gw-emp" v-for="item in sEmps">
              <div class="emp-st">
                <span class="name" @click="setLEmp(item,2)" @dblclick="setDEmp(item,2)">{{item.nickName}}</span>
                <div class="emp-st-btn">
                  <div class="leftActive" v-if="item.status==1" @click="setUnchecked(2)">
                    轮
                  </div>
                  <div class="rightActive" v-if="item.status==2" @click="setUnchecked(2)">
                    点
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="imt-gw" v-if="tEmps.length>0">
          <span class="gw-title">{{tName}}</span>
          <div class="emp-h">
            <div class="gw-emp" v-for="item in tEmps">
              <div class="emp-st">
                <span class="name" @click="setLEmp(item,3)" @dblclick="setDEmp(item,3)">{{item.nickName}}</span>
                <div class="emp-st-btn">
                  <div class="leftActive" v-if="item.status==1" @click="setUnchecked(3)">
                    轮
                  </div>
                  <div class="rightActive" v-if="item.status==2" @click="setUnchecked(3)">
                    点
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getToken} from "../../../utils/auth";

  let timer = null;
  export default {
    name: "billing-item",
    components: {},
    data() {
      return {
        timer: "",
        orderId: '',
        categoryList: [],
        datas: [],
        dataIndex: -1,
        catIndex: 0,
        catId: '',
        items: [],
        ritems: [],
        total: 0,

        exist: false,
        fEmps: [],
        sEmps: [],
        tEmps: [],
        fName: '',
        sName: '',
        tName: '',
        memberCard: {},
      };
    },
    props: {
      mc: Object,
      itemData: Array,
      itemTotal: Number,
    },
    async created() {
      this.memberCard = this.mc
      await this.getCategoryList();
      await this.getItemList();
      this.getEmpList();
      // this.addRuDan()
    },
    methods: {

      //获取项目分类列表
      async getCategoryList() {
        let res = await this.$get("/sw/getSwCategoryList", {token: getToken(), catType: 3});
        this.categoryList = res.categoryList;
        if (res.categoryList.length > 0) {
          this.catId = res.categoryList[0].id
        }
      },

      //获取项目列表
      async getItemList() {
        if (this.catId) {
          let res = await this.$get("/sw/getSwItemList", {catId: this.catId, itemType: 1});
          this.items = res.items;
        }
      },


      //添加项目
      addItem(item) {
        this.datas.push({
          itemId: item.id,
          categoryId: item.categoryId,
          itemName: item.itemName,
          price: item.price,
          actual: 0,
          first: item.first,
          second: item.second,
          third: item.third,
          cost: item.cost,
          itemSign: 0,
          femp: {},
          semp: {},
          temp: {},
        });
        this.dataIndex = this.datas.length - 1
        this.$refs.singleTableRef.setCurrentRow(this.datas[this.dataIndex], true);
        var to = parseFloat(this.total) + parseFloat(item.price)
        this.total = to.toFixed(1)
        this.$emit('setItems', this.datas, this.total)
      },

      //获取员工列表
      async getEmpList() {
        let res = await this.$get("/sw/getSwEmpList", {token: getToken()});
        this.fEmps = res.fEmps;
        this.sEmps = res.sEmps;
        this.tEmps = res.tEmps;
        this.fName = res.fName;
        this.sName = res.sName;
        this.tName = res.tName;
        this.exist = res.exist;
      },

      //移除已选项目
      delItem(index) {
        let item = this.datas[index];
        this.datas.splice(index, 1);
        var to = parseFloat(this.total) - parseFloat(item.price);
        this.total = to.toFixed(1)
        this.$emit('setItems', this.datas, this.total)
        this.getEmpList();
        this.$refs.singleTableRef.setCurrentRow(-1)
      },

      choiceCat(index, catId) {
        this.catIndex = index
        this.catId = catId
        this.getItemList()
      },

      //计算总金额
      calculation() {
        var price = 0
        this.datas.forEach(item => {
          if (item.price) {
            var to = parseFloat(price) + parseFloat(item.price)
            price = to.toFixed(1)
          }
        })
        this.total = price
        this.$emit('setItems', this.datas, this.total)
      },


      setItemList(items, total) {
        this.total = total
        this.datas = items
      },

      setMemberCard(item) {
        this.memberCard = item
      },

      setRow(item) {
        this.dataIndex = this.datas.indexOf(item);
        this.setEmpStatus(item)
      },

      //设置员工
      async setLEmp(item, type,) {
        if (this.dataIndex == -1) {
          return;
        }
        if (this.datas.length > 0) {
          var is = 1;
          this.setUnchecked(type);
          if (type == 1) {
            this.datas[this.dataIndex].femp = {
              empId: item.id,
              empName: item.nickName,
              station: type,
              gradeId: item.gradeId,
              status: is
            }
          }
          if (type == 2) {
            this.datas[this.dataIndex].semp = {
              empId: item.id,
              empName: item.nickName,
              station: type,
              gradeId: item.gradeId,
              status: is
            }
          }
          if (type == 3) {
            this.datas[this.dataIndex].temp = {
              empId: item.id,
              empName: item.nickName,
              station: type,
              gradeId: item.gradeId,
              status: 1
            }
          }
          item.status = 1
        }
      },

      async setDEmp(item, type) {
        if (this.dataIndex == -1) {
          return;
        }
        if (this.datas.length > 0) {
          this.setUnchecked(type);
          if (type == 1) {
            this.datas[this.dataIndex].femp = {
              empId: item.id,
              empName: item.nickName,
              station: type,
              gradeId: item.gradeId,
              status: 2
            }
          }
          if (type == 2) {
            this.datas[this.dataIndex].semp = {
              empId: item.id,
              empName: item.nickName,
              station: type,
              gradeId: item.gradeId,
              status: 2
            }
          }
          if (type == 3) {
            this.datas[this.dataIndex].temp = {
              empId: item.id,
              empName: item.nickName,
              station: type,
              gradeId: item.gradeId,
              status: 2
            }
          }
          item.status = 2
        }
      },

      // 将选中的人状态更改为没选中
      setUnchecked(type) {
        if (type == 1) {
          this.changeEmpFalse(this.fEmps);
          this.datas[this.dataIndex].femp = {}
        }
        if (type == 2) {
          this.changeEmpFalse(this.sEmps);
          this.datas[this.dataIndex].semp = {}
        }
        if (type == 3) {
          this.changeEmpFalse(this.tEmps)
          this.datas[this.dataIndex].temp = {}
        }
      },

      // 切换项目更改员工选中状态
      setEmpStatus(item) {
        this.changeEmpFalse(this.fEmps);
        this.changeEmpFalse(this.sEmps);
        this.changeEmpFalse(this.tEmps);
        if (item.femp.station == 1) {
          this.changeEmpTrue(this.fEmps, item.femp)
        }
        if (item.semp.station == 2) {
          this.changeEmpTrue(this.sEmps, item.semp)
        }
        if (item.temp.station == 3) {
          this.changeEmpTrue(this.tEmps, item.temp)
        }
      },

      //更改状态
      changeEmpTrue(emps, emp) {
        emps.forEach(item => {
          if (emp.empId == item.id) {
            item.status = emp.status
          } else {
            item.status = 0
          }
        })
      },

      //更改状态
      changeEmpFalse(emps) {
        emps.forEach(item => {
          item.status = 0
        })
      },
    },
  };
</script>


<style lang="scss" scoped>
  .item-top {
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  .imt-left {
    width: 60%;
    border-right: #F2F2F2 solid 5px;
    height: 100%;
    overflow: hidden;
  }

  .imt-cats {
    width: 100%;
    border-bottom: #E6E6E6 solid 1px;
    height: 47px;
    line-height: 47px;
    overflow: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .imt-title {
    flex-shrink: 0;
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    margin: 0 5px;
  }

  .imt-title1 {
    height: 26px;
    line-height: 26px;
    text-align: center;
    font-weight: bold;
  }

  .activeCat {
    color: red;
    border-bottom: red solid 2px;
  }

  .imt-list {
    height: 90%;
    overflow: hidden; // 超出部分隐藏
    overflow-y: scroll; // 设置y轴方向的滚动条
  }

  .imt-items {
    width: 95%;
    padding: 0px 2%;
    display: flex;
    flex-flow: row wrap;
  }

  .imt-item {
    box-sizing: border-box;
    border: #DDDDDD solid 1px;
    width: 138px;
    height: 60px;
    padding: 4px;
    margin: 6px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: center;
    /*width: calc((100% - 60px) / 5); // 我这里一行显示4个 所以是/4  一行显示几个就除以几*/
    /*min-width: calc((100% - 60px) / 5);*/
    /*max-width: calc((100% - 60px) / 5);*/
    /*&:nth-child(4n + 4) {*/
    /*  margin-right: 0;*/
    /*}*/
  }

  .imt-name {
    margin-top: 3px;
    line-height: 20px;
    margin-left: 5px;
    font-size: 13px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .imt-price {
    line-height: 20px;
    margin-left: 5px;
    color: red;
    font-size: 13px;
    text-align: center;
  }

  .imt-right {
    width: 41%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .buy-item {
    height: 65%;
    border-right: #F2F2F2 solid 5px;
    font-size: 11px;
  }


  .imt-emp {
    height: 35%;
    display: flex;
    flex-direction: row;
    border-top: #F2F2F2 solid 5px;
  }

  .emp-h {
    overflow: hidden;
    overflow-y: scroll;
    height: calc(100% - 35px);
  }

  .imt-gw {
    width: 100%;
    border-right: #F2F2F2 solid 5px;
  }

  .gw-title {
    width: 100%;
    text-align: center;
    display: block;
    height: 26px;
    line-height: 26px;
    border-bottom: #F2F2F2 solid 1px;
    margin-bottom: 5px;
  }

  .gw-emp {
    display: flex;
    height: 26px;
    line-height: 26px;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .gw-emp .name {
    width: 80%;
    margin-left: 12px;
  }

  .emp-st {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  .emp-st-btn {
    position: relative;
    width: 50px;
    height: 18px;
    line-height: 18px;
    border-radius: 9px;
    color: #c8c9cc;
    padding: 0 6px;
    box-sizing: border-box;
  }

  .g-between {
    display: flex;
    justify-content: space-between;
  }

  .emp-st-btn .lun {
    width: 20px;
    text-align: center;
    font-size: 13px;
    height: 20px;
    line-height: 20px;
    cursor: pointer;
    background-color: #EEEEEE;
  }

  .emp-st-btn .leftActive {
    position: absolute;
    width: 22px;
    height: 22px;
    background: #616eb0;
    line-height: 22px;
    text-align: center;
    color: #fff;
    font-weight: 500;
    transform: translateY(-50%);
    top: 50%;
    border-radius: 50%;
    transition: all 0.8s;
    box-shadow: 0px 2px 5px rgba(17, 17, 17, 0.2);
    cursor: pointer;
  }

  .emp-st-btn .rightActive {
    position: absolute;
    width: 22px;
    height: 22px;
    background: #616eb0;
    line-height: 22px;
    text-align: center;
    color: #fff;
    font-weight: 500;
    transform: translateY(-50%);
    top: 50%;
    border-radius: 10px;
    transition: all 0.8s;
    box-shadow: 0px 2px 5px rgba(17, 17, 17, 0.2);
    cursor: pointer;
  }


  .bp-nr {
    display: flex;
    flex-direction: column;
  }

  .bp-sp {
    width: 100%;
    border: #C4C4C4 solid 1px;
    margin: 1px 0px;
    border-radius: 3px;
  }

  ::v-deep .el-table__body tr.current-row > td {
    color: #000000;
    background: #EEE9E9 !important;
  }


</style>
