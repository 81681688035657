<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :center="true"
      title="充值"
      :visible.sync="payVisible"
      width="700px"
      :before-close="closeEvent"
    >
      <div class="swpay">
        <div class="swpay-content">
          <div class="swpay-left">
            <div class="consumption">
              <span class="yi-left">会员名称:</span>
              <span class="yi-right" style="text-align: center">{{mc.cardName}}</span>
            </div>
            <div class="discount">
              <span class="yi-left">充值金额:</span>
              <span class="yi-right" style="color: #e82742">
                   <el-input-number v-model="totalAmount" :disabled="mc.changeAmount==1" :min="0" size="mini"
                                    style="width: 160px;display: block;margin: auto"
                                    placeholder="请输入内容"></el-input-number>
              </span>

            </div>
            <div class="discount">
              <span class="yi-left">赠送金额:</span>
              <span class="yi-right" style="color: #e82742">
                <el-input-number v-model="giveAmount" :disabled="mc.changeAmount==1" :min="0" size="mini"
                                 style="width: 160px;display: block;margin: auto"
                                 placeholder="请输入内容"></el-input-number>
              </span>
            </div>
            <div class="emp-discount">
              <div>
                <span class="emp-name">选择人员:</span>
                <el-select v-model="station" placeholder="选择岗位" size="mini" @change="setStation" style="width: 190px">
                  <el-option
                    v-for="item in gws"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                  </el-option>
                </el-select>
                <el-select
                  style="margin-left: 5px;width: 190px"
                  size="mini"
                  v-model="empIds"
                  multiple
                  collapse-tags
                  @change="addEmp"
                  placeholder="请选择员工"
                >
                  <el-option
                    v-for="item in emps"
                    :key="item.id"
                    :label="item.nickName"
                    :value="item.id">
                  </el-option>
                </el-select>
              </div>
              <div class="emp-list">
                <div class="emp-item" v-for="item in empitems">
                  <!--                  <span style="width: 30px"><i class="el-icon-remove-outline" style="cursor: pointer"></i></span>-->
                  <span style="width: 262px">员工名称:<span style="margin-left: 18px">{{item.empName}}</span></span>
                  <span>
                    业绩:
                    <span style="margin-left: 3px"><el-input-number v-model="item.ach" :min="0" size="mini"
                                                                    style="width: 152px;"></el-input-number></span>
                  </span>
                </div>
              </div>
            </div>
            <div class="swpay-choice">
              <span style="width: 130px;padding-left: 40px;line-height: 70px">支付方式:</span>
              <div class="mode">
                <div class="mode1">
                  <div
                    style="cursor: pointer"
                    class="icon"
                    v-for="(item, index) of iconList"
                    :key="index"
                    @click="changeList(item.type, item.iconDec)"
                  >
                    <img class="imgfont" :class="{ checked: item.type == iconIndex }" :src="item.iconContent">
                    <p class="iconDec">{{ item.iconDec }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="payment-footer">
          <el-button type="primary" class="settlement" :loading="isDisabled" @click="toSaveOrder(iconIndex)">
            {{isDisabled==true?'结算中':'结算'}}
          </el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      center
      title="提示"
      :visible.sync="titleVisible"
      width="15%"
      :before-close="closeTitel"
    >
      <div
        style="margin: 0 auto; text-align: center; width: 100%; height: 100%"
      >
        <span style="font-weight: bold; font-size: 18px">{{
          titleContent
        }}</span>
      </div>
      <br/>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      center
      :title="zfTitle"
      :visible.sync="ptVisible"
      width="400px"
      :before-close="closeCode"
    >
      <div style="margin: 0 auto; text-align: center; width: 100%; height: 100%;margin-top: -20px">
        <el-input v-model="dynamicId" size="mini" ref="dynamic" style="width: 260px;display: block;margin: auto"
                  placeholder="请输入内容"></el-input>
        <img :src="qrCode" style="width: 150px;height: 150px;">
      </div>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      center
      title="填写金额"
      :visible.sync="aVisible"
      width="288px"
    >
      <div>
        <el-input-number v-model="amount" :min="0" size="mini" style="width: 260px;display: block;margin: auto"
                         placeholder="请输入内容"></el-input-number>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="setAmount"
        >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">是否退出结算页面？</div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="delVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="fanhui"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {getToken} from "../../../utils/auth";

  export default {
    name: "czPay",
    data() {
      return {
        mc: {},
        timer: null,
        dynamicId: "",
        iconIndex: 2,
        iconList: [
          {
            iconContent: require("../../../assets/images/xj.png"),
            iconDec: "现金",
            type: "2",
            balance: "",
          },
          {
            iconContent: require("../../../assets/images/wx.png"),
            iconDec: "微信",
            type: "3",
            balance: "",
          },
          {
            iconContent: require("../../../assets/images/zfb.png"),
            iconDec: "支付宝",
            type: "4",
            balance: "",
          },
        ],

        totalAmount: 0,
        giveAmount: 0,
        amount: 0,
        aType: '',
        aVisible: false,

        gws: [],
        emps: [],
        fEmps: [],
        sEmps: [],
        tEmps: [],
        station: '',
        empIds: '',
        empitems: [],


        isDisabled: false,

        zfTitle: '',
        paySn: "",
        payNo: "",
        qrCode: "",
        ptVisible: false,
        alPay: '',
        titleContent: "",
        titleVisible: false,

        delVisible: false,
      };
    },
    props: {
      payVisible: Boolean,
      memberCard: Object,
    },
    watch: {
      dynamicId(val) {
        if (val != null && val.length == 18) {
          this.payMethod();
        }
      },
    },
    created() {
      this.mc = this.memberCard
      this.totalAmount = this.mc.cardAmount
      this.giveAmount = this.mc.giveAmount
      this.getEmpList()
      this.getDept()
    },
    destroyed() {
      // 在页面销毁后，清除计时器
      this.beforeDestroy()
    },
    methods: {
      async getDept() {
        let res = await this.$get("/sw/getSwDept", {token: getToken()});
        if (res.dept.isBtn == 0) {
          this.iconList.push(
            {
              iconContent: require("../../../assets/images/sjia.png"),
              iconDec: "商家",
              type: "9",
              balance: "",
            },
          )
        }
      },
      addEmp(val) {
        var ids = val
        if (this.empitems.length < ids.length) {
          let id = ids.slice(-1);
          let tcxm = this.emps.find(it => {
            return it.id == id
          })
          let item = {
            employeeId: tcxm.id,
            empName: tcxm.nickName,
            gradeId: tcxm.gradeId,
            station: this.station,
            quantity: 1,
            ach: this.totalAmount,
          }
          this.empitems.push(item)
        } else {
          this.empitems = this.empitems.filter(x => ids.some(y => y === x.employeeId))
        }
      },

      //获取员工列表
      async getEmpList() {
        let res = await this.$get("/sw/getSwEmpList", {token: getToken()});
        this.fEmps = res.fEmps;
        this.sEmps = res.sEmps;
        this.tEmps = res.tEmps;
        if (res.fName) {
          this.gws.push({'name': res.fName, "value": 1,})
        }
        if (res.sName) {
          this.gws.push({'name': res.sName, "value": 2,})
        }
        if (res.tName) {
          this.gws.push({'name': res.tName, "value": 3,})
        }
      },
      setStation() {
        this.empitems = []
        this.empIds = []
        if (this.station == 1) {
          this.emps = this.fEmps
        }
        if (this.station == 2) {
          this.emps = this.sEmps
        }
        if (this.station == 3) {
          this.emps = this.tEmps
        }
      },

      toSetAmount(type) {
        this.aType = type
        this.aVisible = true
        this.amount = 0
      },

      setAmount() {
        if (this.aType == 0) {
          this.totalAmount = this.amount
        } else {
          this.giveAmount = this.amount
        }
        this.aVisible = false
      },


      closeTitel() {
        this.titleVisible = false;
      },

      closeCode() {
        this.ptVisible = false;
        this.beforeDestroy()
      },

      closeEvent() {
        this.delVisible = true;
      },

      closePay() {
        this.$emit("closePay");
      },
      fanhui() {
        this.$emit("fanhui");
      },
      async changeList(index) {
        this.iconIndex = index;
      },

      async toSaveOrder(index) {
        this.mcRecharge()
      },

      async mcRecharge() {
        let ach = 0;
        this.empitems.forEach(item => {
          ach = parseFloat(ach + item.ach)
        })
        if (ach > this.totalAmount) {
          this.$message.error("员工业绩大于充值金额!")
          return;
        }
        this.isDisabled = true
        let data = {
          mcId: this.mc.id,
          mcName: this.mc.cardName,
          xkCmn: this.mc.xkCmn,
          empitems: JSON.stringify(this.empitems),
          totalAmount: this.totalAmount,
          giveAmount: this.giveAmount,
          payType: this.iconIndex,
          token: getToken()
        };
        let res = await this.$post("/sw/mcRecharge", data);
        if (res.code == 200) {
          this.isDisabled = false
          this.orderId = res.orderId
          if (res.status == 0) {
            if (res.payType == 3 || res.payType == 4) {
              this.toPay(res.payType, res.orderId)
            }
          } else {
            this.$emit("closePay");
          }
        } else {
          this.isDisabled = false
          this.$message.error(res.message);
        }
      },

      async toPay(status) {
        let data = {
          token: getToken(),
          payType: status,
          payName: "会员卡充值",
          orderId: this.orderId,
        };
        this.isDisabled = true
        let res = await this.$post("/sw/precreate", data);
        this.isDisabled = false
        if (res.code == 200) {
          this.qrCode = res.qrCode;
          if (status == 3) {
            this.wxNo = res.payNo;
            this.wxSn = res.sn;
          } else if (status == 4) {
            this.alNo = res.payNo;
            this.alSn = res.sn;
          }
          this.beforeDestroy();
          this.dataRefreh();
          this.ptVisible = true;
          this.$nextTick(() => {
            this.$refs.dynamic.focus() // 设置焦点
          })
        } else {
          this.$message.error(res.message)
        }
      },

      // 定时刷新数据函数
      dataRefreh() {
        // 计时器正在进行中，退出函数
        if (this.timer != null) {
          return;
        }
        // 计时器为空，操作
        this.timer = setInterval(() => {
          this.getPayStatus(); //加载数据函数
        }, 3000);
      },

      // 停止定时器
      beforeDestroy() {
        clearInterval(this.timer); //清除计时器
        this.timer = null; //设置为null
      },

      async getPayStatus() {
        let data = {
          orderId: this.orderId
        };
        let res = await this.$get("/sw/getPayStatus", data);
        if (res.code == 200) {
          this.titleVisible = true;
          this.titleContent = res.message;
          this.$emit("closePay");
          this.beforeDestroy();
        } else {
          this.$message.error("正在支付中")
        }
      },

      async payMethod() {
        if (!this.dynamicId) {
          this.$message.error("请扫付款码");
          return;
        }
        let data = {
          token: getToken(),
          dynamicId: this.dynamicId,
          payType: this.iconIndex,
          payName: "充值会员卡",
          orderId: this.orderId,
        };
        let res = await this.$post("/sw/payMethod", data);
        if (res.code == 200) {
          this.titleVisible = true;
          this.titleContent = "正在支付";
          this.beforeDestroy();
          this.dataRefreh();
        } else {
          this.$message.error(res.message);
          this.titleContent = res.message;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .swpay {
    width: 100%;
    height: auto;
    margin-top: -12px;
  }

  .swpay-content {
    width: 100%;
    height: 83%;
    border-top: #ececec solid 1px;
    display: flex;

    .swpay-left {
      width: 100%;
      height: 100%;
    }

    .yi-left {
      width: 40%;
      display: inline-block;
      font-size: 14px;
    }

    .emp-name {
      width: 68px;
      display: inline-block;
      font-size: 14px;
    }

    .yi-right {
      display: inline-block;
      width: 30%;
      text-align: right;
      margin-right: 2%;
    }
  }

  .swpay-choice {
    display: flex;
    flex-direction: row;
    border-bottom: #ececec solid 1px;
    height: auto;

    .mode {
      width: 100%;
      display: flex;
    }
  }

  .consumption,
  .discount {
    height: 40px;
    line-height: 40px;
    padding-left: 40px;
    border-bottom: #ececec solid 1px;
    color: #666666;
  }

  .emp-discount {
    height: 238px;
    line-height: 40px;
    padding-left: 40px;
    border-bottom: #ececec solid 1px;
    color: #666666;
    display: flex;
    flex-direction: column;
  }

  .emp-list {
    margin-top: 6px;
    height: 188px;
    overflow: scroll;
  }

  .emp-item {
    height: 32px;
    line-height: 32px;
    margin-bottom: 3px;
    display: flex;
    flex-direction: row;
  }

  //支付方式
  .mode1 {
    .icon {
      width: 55px;
      height: 70px;
      line-height: 40px;
      float: left;
      margin-top: 5px;
      text-align: center;

      .imgfont {
        width: 35px;
        height: 35px;
      }
    }

    .imgfont.checked {
      border-radius: 50%;
      border: #eb2742 solid 1px;
      background-color: #eb2742;
      color: #fff;
    }
  }

  .payment-footer {
    width: 100%;
    height: 15%;

    .settlement {
      width: 100%;
      height: 50px;
      border: 0;
      background-color: #eb2742;
      cursor: pointer;
      color: #fff;
    }
  }

  .iconDec {
    width: 55px;
    margin-top: -20px;
    font-size: 13px;
  }
</style>
